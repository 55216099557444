import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {Hausverwaltung, Invoice} from '../../../../../../../database-models';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public itemData = [
    {
      name: 'Asus Zenfone 3 Zoom ZE553KL Dual Sim (4GB, 64GB)',
      quantity: 2,
      price: 450
    },
    {
      name: 'HP Pavilion 15-au103TX 15.6˝ Laptop Red',
      quantity: 1,
      price: 550
    },
    {
      name: 'Canon EOS 77D',
      quantity: 1,
      price: 875
    },
  ];

  public invoidId: string;
  public invoice: Invoice;

  constructor(
    public route: ActivatedRoute,
    private api: ApiService
  ) {
    this.route
      .params
      .subscribe(params => {
        this.invoidId = params['id'];
        this.getInvoice();
      });
  }

  ngOnInit() {
  }

  private getInvoice(): void {
    this.api.getInvoice(this.invoidId).subscribe((invoice: Invoice) => {
      this.invoice = invoice;
    });
  }

}
