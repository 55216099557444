<ng-container *ngIf="invoice">
  <div id="invoice" class="p-h-30">
    <img class="invoice-logo" src="assets/images/logo/logo.svg" alt="">
    <div class="inline-block float-right">
      <address class="p-t-150">
        <span class="font-weight-semibold text-dark">NetBrick - IT Solutions</span><br>
        <span>Veilchenweg 14</span><br>
        <span>84186 Vilsheim</span><br>
      </address>
    </div>

    <div class="row m-t-15 lh-2">
      <div class="col-sm-7">
        <div class="p-t-150 inline-block float-left">
          <div class="address-letter">
            NetBrick - IT Solutions &bull; Veilchenweg 14 &bull; 84186 Vilsheim
          </div>
          <address class="m-t-10 lh-1-4">
            <div [innerHTML]="invoice.recipient | nl2br"></div>
          </address>
        </div>
      </div>
    </div>

    <div class="row m-t-20 lh-2">
      <div class="col-sm-12">
        <div class="m-t-20">
          <h2>RECHNUNG</h2>
        </div>
        <div>
          <div class="text-dark text-uppercase d-inline-block">
            <span class="font-weight-semibold text-dark">Rechnungs-Nr :</span> HA-{{ invoice.nr }}
          </div>
        </div>
        <div class="text-dark text-uppercase d-inline-block">
          <span class="font-weight-semibold text-dark">Datum :</span> 16.03.2021
        </div>

      </div>
      <div class="col-sm-3">

      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-sm-12">
        <nz-table #orderTable [nzData]="invoice.invoicepositions" [nzShowPagination]="false">
          <thead>
          <tr>
            <th>Pos.</th>
            <th>Bezeichnung</th>
            <th>Menge</th>
            <th style="min-width: 100px" class="align-right">Einzel €</th>
            <th style="min-width: 110px" class="align-right">Gesamt €</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of orderTable.data; let i = index;">
            <td>{{i + 1}}</td>
            <td>{{item.description}}</td>
            <td>{{item.quantity}}</td>
            <td class="align-right">{{item.single_netto | number:'1.2-2':'de'}}</td>
            <td class="align-right">
              {{item.total_netto | number:'1.2-2':'de'}}
            </td>
          </tr>
          <tr>
            <td colspan="4">
              Zwischensumme (netto)
            </td>
            <td class="align-right">{{ invoice.total_netto | number:'1.2-2':'de' }}</td>
          </tr>
          <tr>
            <td colspan="4">
              Umsatzsteuer {{ invoice.vat }}%
            </td>
            <td class="align-right">{{ invoice.total_vat | number:'1.2-2':'de' }}</td>
          </tr>
          <tr class="sum-row">
            <td colspan="4">
              Gesamtbetrag
            </td>
            <td class="align-right">{{ invoice.total_brutto | number:'1.2-2':'de' }}</td>
          </tr>
          </tbody>
        </nz-table>

        <div class="row m-t-30 lh-2">
          <div class="col-sm-12">
            <div class="border top bottom p-v-20">
              <p class="text-opacity"><small>Es gelten die AGB von NetBrick - IT Solutions.<br>
                Überweisen Sie bitte den Betrag ohne Abzug innerhalb von 14 Tagen nach Erhalt der Rechnung auf unser Konto.</small></p>
            </div>
          </div>
        </div>
        <div class="lh-1-2 m-v-20 inline-block float-right text-right">
          <small>
            Florian Gerhard<br>
            comdirect bank<br>
            IBAN: DE61 2004 1111 0368 6458 00 <br>
            BIC: COBADEHDXXX
          </small>


        </div>
        <div class="row m-v-20">

          <div class="col-sm-6 inline-block float-left">
            <small><span class="font-weight-semibold text-dark">USt-IdNr.:</span> DE327053546</small>
            <br>
            <small><span class="font-weight-semibold text-dark">Inhaber:</span> Florian Gerhard</small>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>
