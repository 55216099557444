import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {SuperadminAuthenticationService} from '../../super-admin/services/superadmin-authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard  {
  constructor(private router: Router, private authenticationService: SuperadminAuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.currentSuperAdmin.pipe(map(superAdmin => {
      if (superAdmin) {
        return true;
      }
      this.router.navigate(['super-admin/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }));
  }

}
